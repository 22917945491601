@use "@themuse/design-system/lib/styles/typography" as *;
@use "@themuse/design-system/lib/styles/colors" as *;
@use "@themuse/design-system/lib/styles/mixins";
@import "styles/colors";

@mixin post-section-divider-color-styling($color) {
    svg {
        #Plane { // stylelint-disable-line selector-id-pattern
            fill: $color;
        }
    }

    span {
        color: $color;
    }

    .color-solid-border {
        border-top: 5px solid $color;
        width: 100%;
    }
}

@mixin post-section-divider() {
    .double-dashed-separator,
    .single-line-dashed-separator {
        border-top: 2px dashed $tm-silver;
        margin-top: 10px;
        width: 100%;
    }

    .double-dashed-separator {
        border-bottom: 2px dashed $tm-silver;
        height: 15px;
    }

    .single-line-dashed-separator {
        margin-bottom: 15px;
    }

    .section-divider {
        margin-bottom: 20px;
        padding-top: 10px;
        position: relative;

        svg {
            position: absolute;
            top: 7px;
        }

        h2 {
            @include mixins.viewport("sm") {
                font-size: 36px;
            }
            font-family: $nunito;
            font-size: 24px;
            font-weight: 900;
            color: $tm-medium-gray;
            margin: 10px 0 0;

            br {
                display: none;
            }
        }

        span {
            @include mixins.viewport("sm") {
                font-size: 18px;
            }
            font-family: $nunito;
            font-weight: 700;
            display: block;
            font-size: 14px;
            line-height: 18px;
            margin: 18px 0 10px;
            text-transform: uppercase;
        }

        &.tip-block {
            span {
                display: block;
                margin-top: 0;
                text-transform: capitalize;
            }
        }

        .section-divider--lime {
            @include post-section-divider-color-styling($tm-lime);
        }

        .section-divider--green {
            @include post-section-divider-color-styling($tm-green);
        }

        .section-divider--muse-blue {
            @include post-section-divider-color-styling($tm-blue);
        }

        .section-divider--royal-blue {
            @include post-section-divider-color-styling($tm-royal-blue);
        }

        .section-divider--purple {
            @include post-section-divider-color-styling($tm-purple);
        }

        .section-divider--lilac {
            @include post-section-divider-color-styling($tm-lilac);
        }

        .section-divider--fuchsia {
            @include post-section-divider-color-styling($tm-fuchsia);
        }

        .section-divider--rose {
            @include post-section-divider-color-styling($tm-rose);
        }

        .section-divider--tangerine {
            @include post-section-divider-color-styling($tm-tangerine);
        }

        .section-divider--orange {
            @include post-section-divider-color-styling($tm-orange);
        }
    }
}
