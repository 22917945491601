/* stylelint-disable color-no-hex */
/* stylelint-disable selector-id-pattern */
@use "@themuse/design-system/lib/styles/colors" as *;
@import "styles/section-dividers";
@include post-section-divider;

:root {
    --primary: $primary-1005;
    --primary-20: $primary-20;
    --primary-80: $primary-80;
    --secondary: $secondary-100;
    --white: $neutral-white;
}

html {
    scroll-behavior: smooth;
    text-size-adjust: 100%;
}

body {
    background-color: var(--primary-20);
    color: var(--secondary);
    margin: 0;
    padding: 0;
    text-size-adjust: 100%;
}

a {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: background 200ms ease-out, color 100ms ease-out;
}

a:hover,
a:focus {
    outline: none;
    text-decoration: none;
}

.inline {
    display: inline;
}

// Common grid for all elements using a 12 column grid
.grid {
    display: grid;
    grid-column: span 12;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
}

.mv-ad-box.mv-ad-box {
    height: auto !important;
    min-height: 50px;
}

#sidebar_btf_placeholder {
    margin-top: 20px;
}

.jw-reset {
    &.jw-button-container {
        display: none !important;
    }
}

[class^="Layout_pageWrapper"] {
    position: relative;
}
