@use "@themuse/design-system/lib/styles/colors" as *;
@use "@themuse/design-system/lib/styles/mixins";

.jwplayerModule {
    flex: 1;
    height: 100%;
    width: 100%;

    .jw-icon {
        color: white !important;
    }

    .jw-controls-backdrop {
        .jw-reset {
            background: none;
        }
    }

    .jw-controls {
        .jw-reset {
            background: none;
        }
    }

    .jw-controlbar {
        background: rgba(23, 35, 34, 0.75);
        border-radius: 3px;
        left: 50%;
        margin-bottom: 7px;
        transform: translateX(-50%);
        width: 98%;
    }
}

.jw-state-playing,
.jw-state-paused,
.jw-state-buffering,
.jw-state-complete {
    .jw-controls {
        .jw-display,
        .jw-icon-settings {
            display: none !important;
        }

        .jw-controlbar {
            .jw-icon-playback {
                display: flex !important;
            }
        }
    }
}

.jwplayer-container {
    width: 700px;
    height: 400px;
}

.jw-state-idle {
    .jw-display-icon-container {
        bottom: initial;
        left: 50%;
        margin: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        .jw-icon {
            background-color: $tm-dark-gray;
            border-radius: 3px;
            height: 30px !important;
            padding: 3px 12px;
            width: 30px !important;

            &:hover {
                background: $tm-blue;
            }

            @include mixins.viewport("sm") {
                height: 40px !important;
                padding: 4px 15px;
                width: 40px !important;
            }
        }

        @include mixins.viewport("sm") {
            bottom: 7px;
            left: 7px;
            margin-left: 7px;
            top: auto;
            transform: translate(0);
        }
    }

    .jw-preview {
        background-color: white;
    }

    .jw-breakpoint-6 {
        .jw-display-icon-container {
            .jw-icon {
                @include mixins.viewport("lg") {
                    height: 55px !important;
                    padding: 7px 24px;
                    width: 55px !important;
                }
            }
        }
    }
}
