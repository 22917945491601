/* stylelint-disable color-no-hex */

// General Colors
$tm-overcast: #E8ECEE;
$tm-pavement: #C1C1C1;
$cta-blue: #366FFB;
$dimgray: #767676;
$med-gray: #A3A3A3;
$light-gray: #D8D8D8;
$tm-muse-blue-70: #86CBDE;

// Social Colors
$tm-pinterest: #BD081C;
$tm-facebook: #3B5998;
$tm-linkedin: #0077B5;
$tm-twitter: #00ACED;
$tm-instagram: #DD2A7B;
$tm-google: #DB3236;
$social-email-blue: #58AFF6;

// Section Divider Colors
$tm-green: #0ABA8A;
$tm-lime: #8BC34A;
$tm-purple: #5737B3;
$tm-lilac: #9575CD;
$tm-fuchsia: #BA68C8;
$tm-rose: #E57373;
$tm-tangerine: #FF8A65;
$tm-orange: #F16814;
